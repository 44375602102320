/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import axios from 'axios';
import cn from 'clsx';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import 'styles/pricing.scss';

import { signUp } from '../utils/contentProvider/function';
// import OfferCoupon from '../components/OfferCoupon/OfferCoupon';
import { PricingTable } from '../components/PricingTable/PricingTable';
import {
  brandLogosBugTrack,
  bugTrackingPricingTableData,
  // customerFeedbackPricingTableData,
  customerNumber,
  getErrorMessage,
  pricingTableData,
} from '../utils/contentProvider';
import { isValidEmail } from '../utils/validations';
import useForm from '../hooks/useForm';
import NewFaq from '../components/NewFaq/NewFaq';

const faqData = [
  {
    q: `What's the difference between a User and a Admin?`,
    a: 'A project user can only see the projects which are shared with them, either in view, comment or edit access. An Admin, on the other hand, can see all the projects created inside your team as well as create new projects.',
  },
  {
    q: `I'm looking to use only one product from your suite, is it possible?`,
    a: `Absolutely, just visit the product's page from the carousel at the top, click on the product which you'd like to access, sign-up for the same and start using your preferred product.`,
  },
  {
    q: `What if I'm looking to use multiple products from your suite? How do I switch while using one of the products?`,
    a: 'You can do so by signing up for one of those respective products, and then simply switching to the other product. To switch from your main dashboard, check the top-left dropdown menu, then switch to the other product from the dropdown.',
  },
  {
    q: 'Are there any integrations available in ruttl?',
    a: (
      <>
        Currently we have integrated with Jira, Slack, Trello, ClickUp, Zapier,
        asana and Unsplash. We also have our custom webhook, in case you’d like
        to integrate with any other work tool of your preference. In the future,
        we also plan to integrate with many more tools. For more details, please
        visit our
        <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
        page.
      </>
    ),
  },
  {
    q: 'Is there a public roadmap available?',
    a: (
      <>
        Yes, we have our public roadmap. Check out our
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Trello board&nbsp;
        </a>
        for the same.
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const views = {
  individual: 0,
  businesses: 1,
  monthly: 2,
  yearly: 3,
};

const pricingProducts = [
  {
    id: 0,
    btnId: 'wft',
    productName: 'Website Feedback',
    proMonthly: 8,
    proYearly: 6,
    teamMonthly: 30,
    teamYearly: 25,
  },
  {
    id: 1,
    btnId: 'maf',
    productName: 'Mobile App Feedback',
    proMonthly: 5,
    proYearly: 4,
    teamMonthly: 15,
    teamYearly: 12,
  },

  {
    id: 2,
    btnId: 'wft',
    productName: 'Video Annotation',
    proMonthly: 8,
    proYearly: 6,
    teamMonthly: 30,
    teamYearly: 25,
  },
];

// const customerFeedbackOfferings = {
//   free: [
//     '',
//     '1 project',
//     'Messages & File History for 7 Days',
//     'Basic Customization',
//     'Upto 5 Users',
//     'All Integrations',
//   ],
//   pro: [
//     'Unlimited Projects',
//     'Unlimited Messages & File History',
//     'Advanced Widget Customization',
//     '1 admin + upto 50 users',
//     'AI Bot',
//     'Dedicated Success Manager',
//   ],
//   team: [
//     'All in Pro',
//     'Admin users with project management abilities',
//     'Centralised Project Management',
//     'Dedicated Team Workspace',
//     'SAML Support',
//   ],
// };

const commonOfferings = {
  free: [
    '',
    'Upto 5 Users',
    '1 project',
    'Unlimited Guests',
    'All Integrations',
  ],
  pro: [
    'All in Free',
    '1 admin + upto 50 users',
    'Unlimited Projects',
    'Unlimited Guests',
    '10 mins of Video Commenting',
    'Dedicated Success Manager',
  ],
  team: [
    'All in Pro',
    'Admin users with project management abilities',
    'Centralised Project Management',
    'Dedicated Team Workspace',
    'SAML Support',
  ],
};

const pricingTables = [
  {
    id: 0,
    table: pricingTableData,
    enterpriseData: {
      content:
        'Streamline the process of website feedback with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified website feedback process for your enterprise',
      product: 'Website Feedback Tool',
      btnId: 'wft',
    },
  },
  {
    id: 1,
    table: bugTrackingPricingTableData,
    enterpriseData: {
      content:
        'Streamline the bug tracking process with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified bug tracking process for your enterprise',
      product: 'Mobile App Feedback',
      btnId: 'maf',
    },
  },
  {
    id: 2,
    table: pricingTableData,
    enterpriseData: {
      content:
        'Streamline the process of website feedback with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified website feedback process for your enterprise',
      product: 'Website Feedback Tool',
      btnId: 'wft',
    },
  },
];

const testimonials = [
  {
    id: 0,
    content:
      'We love ruttl because of its simplicity and the time it saves. With ruttl, we’ve pushed our web projects to live faster, with lesser costs and fewer hurdles in the process. It has really changed the client experience for us and streamlined our entire process.',
    author: '',
    authorDesignation: 'Digital Business Strategist at AftonNegrea.com',
    imgURL: '/assets/img/afton-negrea.png',
  },
  {
    id: 1,
    content: `I have been thoroughly impressed by ruttl's bug-tracking tool. It has streamlined our team’s development process, allowing us to identify and fix bugs quickly. The user-friendly interface and efficient reporting and workflow have saved us countless hours. I highly recommend ruttl's bug tracking tool to any organization looking to improve their development workflow.`,
    author: 'Piyush Narwan',
    authorDesignation: 'Co-Founder, Aerotime',
    imgURL: '/assets/img/piyush-narwan.png',
  },
  {
    id: 2,
    content: `Our team has benefited tremendously from using ruttl’s customer feedback tool. It has provided us with valuable insights into the bugs & queries faced by our customers and also helped us gather testimonials inside the same platform - enabling us to improve our overall experience. I highly recommend this tool to any business looking to prioritize customer satisfaction.`,
    author: 'Liran Arbiv',
    authorDesignation: 'Owner, CÉCA Studio',
    imgURL: '/assets/img/liran-arbiv.png',
  },
  {
    id: 3,
    content: `I have been thoroughly impressed by ruttl's bug-tracking tool. It has streamlined our team’s development process, allowing us to identify and fix bugs quickly. The user-friendly interface and efficient reporting and workflow have saved us countless hours. I highly recommend ruttl's bug tracking tool to any organization looking to improve their development workflow.`,
    author: 'Piyush Narwan',
    authorDesignation: 'Co-Founder, Aerotime',
    imgURL: '/assets/img/piyush-narwan.png',
  },
];

const ProductPricing = () => {
  const [currentTab, setCurrentTab] = useState(views.businesses);
  // const [productTabs, setProductTabs] = useState(0);
  // const [productTabToggleMobile, setProductTabToggleMobile] = useState(false);
  const [teamDropdown, setTeamDropdown] = useState(false);
  const [teamMembers, setTeamMembers] = useState(5);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [websitePlan, setWebsitePlan] = useState('yearly');

  // const [userCurrency, setUserCurrency] = useState('default');
  // const [userEmail, setUserEmail] = useState('');
  const [fetchingLink /* setFetchingLink */] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [position, setPosition] = useState('');
  const [showDemoCallModal, setShowDemoCallModal] = useState(false);

  const toggleTab = (tab) => () => setCurrentTab(tab);

  const toggleTeamDropdown = () => setTeamDropdown((state) => !state);

  const updateTeamMembers = (e) => {
    setTeamMembers(Number(e.target.closest('button').dataset.size));
    setTeamDropdown(false);
  };

  // function productTabsPricingTrigger(value) {
  //   return () => {
  //     setProductTabs((state) => (state === value ? value : value));
  //     setProductTabToggleMobile(false);
  //   };
  // }
  const productTabs = 0;
  const getPricingTable = () =>
    pricingTables.find((table) => table.id === productTabs).table;

  const getEnterpriseData = () =>
    pricingTables.find((value) => value.id === productTabs).enterpriseData;

  // const productTabsMobileTrigger = () => {
  //   setProductTabToggleMobile((state) => !state);
  // };

  // useEffect(() => {
  //   if (productTabs === 0) {
  //     setCurrentTab(views.individual);
  //   } else {
  //     setCurrentTab(views.monthly);
  //   }
  // }, [productTabs]);

  const getProPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].proYearly
      : pricingProducts[productTabs].proMonthly;

  const getStandardPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].standardYearly
      : pricingProducts[productTabs].standardMonthly;

  const getTeamPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].teamYearly
      : pricingProducts[productTabs].teamMonthly;

  const togglePurchaseModal =
    (payload = false) =>
    // eslint-disable-next-line indent
    () =>
      // eslint-disable-next-line indent
      setShowPurchaseModal(payload);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  const formProps = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
    },
    validations: {
      name: {
        required: true,
      },
      email: {
        required: true,
        rules: [
          {
            validator: (value) => isValidEmail(value),
            message: getErrorMessage('error', 'email'),
          },
        ],
      },
    },
    onSubmit: (payload, reset) => {
      (async () => {
        try {
          setLoading(true);

          await axios.post(
            'https://us-central1-rally-brucira.cloudfunctions.net/contact',
            {
              subject: `Enterprise-Custom-${pricingTables[productTabs].enterpriseData.product}`,
              email: payload.email,
              message: `${payload.name} has shown interest in ${teamMembers} members Custom Enterprise plan.`,
            },
          );

          // reset form
          reset();

          // setPosition(p); // set position in state

          setShowDemoCallModal(true); // open modal
        } catch (e) {
          // eslint-disable-next-line no-alert
          window.alert('Something went wrong, try again');
        } finally {
          setLoading(false);
        }
      })();
    },
  });

  const handleChange = (e) => {
    formProps.onChange(e.target.name, e.target.value);
  };

  // useEffect(() => {
  //   // const CONTAINER_PADDING_HALF = '0.5em';

  //   const pointer = document.getElementById('js-pointer');
  //   const navList = document.querySelector('.product-list-tabs');
  //   const links = Array.from(navList.querySelectorAll('button'));

  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 0; i < links.length; i++) {
  //     const current = links[i];
  //     current.dataset.order = `${i * 100}%`;
  //     current.addEventListener('click', movePointer);
  //   }

  //   // set moving cursor to first element
  //   pointer.style.transform = `translate3d(${links[0].offsetLeft}px,0,0)`;
  //   pointer.style.width = `${links[0].offsetWidth}px`;
  //   pointer.style.height = `${links[0].offsetHeight}px`;

  //   function movePointer(e) {
  //     const currentWidth = e.currentTarget.offsetWidth;
  //     const currentLeft = e.currentTarget.offsetLeft;
  //     const currentheight = e.currentTarget.offsetHeight;

  //     pointer.style.transform = `translate3d(${currentLeft}px,0,0)`;
  //     pointer.style.width = `${currentWidth}px`;
  //     pointer.style.height = `${currentheight}px`;
  //   }
  // }, []);

  const productTable = useRef();

  useEffect(() => {
    const stickyWrapper = document.querySelector('.top-pricing-tabs');
    if (productTable.current) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > productTable.current?.offsetTop - 200) {
          stickyWrapper.classList.add('hidden-tabs');
        } else {
          stickyWrapper.classList.remove('hidden-tabs');
        }
      });
    }
  }, []);

  return (
    <Layout
      hideTopBar
      pricingPage
      product={pricingTables[productTabs].enterpriseData.product}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Pricing & Plans | ruttl"
            description="Planning to use ruttl for your design projects? First projects is free! If you need more, simply choose any pricing plan that suits your team size."
            url="https://ruttl.com/pricing/"
          />
          <main className="styled-pricing-main">
            {/* <div className="top-pricing-tabs">
              <div className="header-margin-inline">
                <div className="flex align-items-center gap-32">
                  <div>
                    <Link to="/" className="logo-header">
                      <img
                        src="/assets/img/ruttl-logo.svg"
                        alt="ruttl logo"
                        title="ruttl logo"
                        width="78"
                        height="22"
                      />
                    </Link>
                  </div>

                  <div className="visible-mob flex-1">
                    <button
                      onClick={productTabsMobileTrigger}
                      type="button"
                      className={`mob-price-product-toggle ${
                        productTabToggleMobile ? 'active' : ''
                      }`}>
                      {pricingProducts.map(({ id, productName }) => (
                        <span
                          key={id}
                          className="center"
                          style={{
                            display: productTabs === id ? 'block' : 'none',
                          }}>
                          {productName}
                        </span>
                      ))}
                      <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                        <path
                          d="m1.333 1.666 5.334 5.333L12 1.666"
                          stroke="#150546"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <div id="magic-nav">
                    <span id="js-pointer"></span>

                    <div
                      className={`product-list-tabs relative  ${
                        productTabToggleMobile ? 'active' : ''
                      }`}>
                      {pricingProducts.map(({ id, productName, btnId }) => (
                        <button
                          key={id}
                          type="button"
                          id={`${btnId}-nav-pricing-tab`}
                          className={productTabs === id ? 'active' : ''}
                          onClick={productTabsPricingTrigger(id)}>
                          {productName}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <section className="pricing-hero grey-light">
              <div className="container">
                <div className="pricing-hero-top">
                  {/* <div className="flex flex-wrap justify-content-between align-items-center"> */}
                  <div className="">
                    <div
                      className="sub-product sub-product--pdf"
                      style={{
                        marginInline: 'auto',
                        display: `${productTabs === 2 ? 'block' : 'none'}`,
                      }}>
                      <img
                        src="/assets/img/add-plugin.svg"
                        alt=""
                        style={{
                          marginRight: '12px',
                        }}
                        width={18}
                      />
                      <span>
                        Video Annotation is a sub product of Website Feedback
                        Tool
                      </span>
                    </div>
                    <div>
                      {pricingProducts.map(({ id }) => (
                        <h1
                          key={id}
                          className="center"
                          style={{
                            display: productTabs === id ? 'block' : 'none',
                          }}>
                          Maximize Your Feedback Potential
                        </h1>
                      ))}

                      {/* <p className="pricing-detail">Pay monthly or annually</p> */}
                    </div>
                    <div className="pricing-toggle-container gap-4 justify-content-center">
                      <div className="pricing-toggle-main">
                        {/* {productTabs === 0 ? ( */}
                        <button
                          type="button"
                          className={
                            websitePlan === 'monthly' ? 'active-price' : ''
                          }
                          onClick={() => setWebsitePlan('monthly')}>
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={
                            websitePlan === 'yearly' ? 'active-price' : ''
                          }
                          onClick={() => setWebsitePlan('yearly')}>
                          Yearly
                        </button>
                        {/* ) : (
                          <>
                            <button
                              type="button"
                              className={
                                currentTab === views.monthly
                                  ? 'active-price'
                                  : ''
                              }
                              onClick={toggleTab(views.monthly)}>
                              Monthly
                            </button>
                            <button
                              type="button"
                              className={
                                currentTab === views.yearly
                                  ? 'active-price'
                                  : ''
                              }
                              onClick={toggleTab(views.yearly)}>
                              Yearly
                            </button>
                          </>
                        )} */}
                      </div>
                      {/* {productTabs !== 0 && (
                        <p>
                          <span> Save 16%</span>
                          <img
                            src="/assets/img/small-green-underline.svg"
                            alt=""
                          />
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-content-between hero-pricing-blocks">
                  <div className="plan-single">
                    <div className="plan-top">
                      <h4>Free</h4>
                      <div className="plan-amount">
                        {pricingProducts.map(({ id }) => (
                          <div key={id}>
                            <div
                              style={{
                                display: productTabs === id ? 'block' : 'none',
                              }}>
                              <>$0</>
                            </div>
                          </div>
                        ))}
                      </div>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        id={`free-get-started-${pricingProducts[productTabs].btnId}-hero-pricing`}
                        className="button button-white blue-text">
                        Get Started
                      </button>

                      <ul className="offerings">
                        {commonOfferings.free.map((offer) => (
                          <li>
                            {offer.trim() !== '' && (
                              <svg
                                width="12"
                                height="9"
                                viewBox="0 0 14 11"
                                fill="none">
                                <path
                                  d="m1 6.2 3.6 3.6L13 1.4"
                                  stroke="#6552FF"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            <span>{offer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="plan-single recommended-plan">
                    <div className="plan-top">
                      <h4 className="link-tag" data-value="Recommended">
                        Pro
                      </h4>
                      <div className="plan-amount">
                        {pricingProducts.map(({ id }) => (
                          <div key={id}>
                            <div
                              style={{
                                display: productTabs === id ? 'block' : 'none',
                              }}>
                              {`$${getProPlanValue()}`}
                            </div>
                          </div>
                        ))}
                      </div>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        id={`pro-get-started-${pricingProducts[productTabs].btnId}-hero-pricing`}
                        onClick={() => signUp(toggleSignup)}
                        className="button ">
                        Get Started
                      </button>

                      <ul className="offerings">
                        {commonOfferings.pro.map((offer) => (
                          <li>
                            <svg
                              width="12"
                              height="9"
                              viewBox="0 0 14 11"
                              fill="none">
                              <path
                                d="m1 6.2 3.6 3.6L13 1.4"
                                stroke="#6552FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>{offer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="plan-single">
                    <div className="plan-top">
                      <h4>Team</h4>

                      <div className="plan-amount">
                        {pricingProducts.map(({ id }) => (
                          <div key={id}>
                            <div
                              style={{
                                display: productTabs === id ? 'block' : 'none',
                              }}>
                              {`$${getTeamPlanValue()}`}
                            </div>
                          </div>
                        ))}
                      </div>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        id={`team-get-started-${pricingProducts[productTabs].btnId}-hero-pricing`}
                        className="button button-white blue-text">
                        Get Started
                      </button>

                      <ul className="offerings">
                        {commonOfferings.team.map((offer) => (
                          <li>
                            <svg
                              width="12"
                              height="9"
                              viewBox="0 0 14 11"
                              fill="none">
                              <path
                                d="m1 6.2 3.6 3.6L13 1.4"
                                stroke="#6552FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span className="flex align-items-center">
                              <span>{offer}</span>
                              {offer.includes('SAML') && (
                                <span className="status-tag coming-soon">
                                  Coming Soon
                                </span>
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* <div
                    className={`plan-single ${
                      currentTab !== views.businesses ? 'recommended-plan' : ''
                    }`}>
                    <div className="plan-top">
                      {currentTab !== views.businesses ? (
                        <h4 className="link-tag" data-value="Recommended">
                          Pro
                        </h4>
                      ) : (
                        <h4>Enterprise</h4>
                      )}
                      <div className="plan-amount">
                        {pricingProducts.map(({ id }) => (
                          <div key={id}>
                            <div
                              style={{
                                display: productTabs === id ? 'block' : 'none',
                              }}>
                              {currentTab !== views.businesses ? (
                                <>
                                  <span>$</span>
                                  {getProPlanValue()}
                                </>
                              ) : (
                                <div style={{ marginBottom: 12 }}>Custom</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <span className="billing-text">
                        / month / user billed&nbsp;
                        {currentTab !== views.yearly && 'yearly'}
                      </span>
                    </div>
                    <div className="plan-main">
                      {currentTab !== views.businesses ? (
                        productTabs !== 2 ? (
                          <button
                            type="button"
                            onClick={() => signUp(toggleSignup)}
                            className="button ">
                            Get Started
                          </button>
                        ) : (
                          <Link to="/customer-feedback-tool" className="button">
                            Get Started
                          </Link>
                        )
                      ) : (
                        <button
                          type="button"
                          onClick={togglePurchaseModal(0)}
                          className="button button-white blue-text"
                          id="pricing-table-button-standard-2">
                          Contact Sales
                        </button>
                      )}
                      <p>
                        {currentTab !== views.businesses
                          ? 'For teams that want unlimited benefits'
                          : 'For larger organisations with multiple teams'}
                      </p>
                    </div>
                  </div> */}
                </div>

                <a href="#pricing-table" className="button-transparent">
                  Explore all features
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7 1.16663V12.8333M12.8333 6.99996L7 12.8333L1.16666 6.99996"
                      stroke="#6552FF"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </section>
            <section className="pricing-testimonial">
              <div className="container">
                <div className="center head">
                  <h2>
                    Loved by&nbsp;
                    {customerNumber}
                    &#43;&nbsp;Creators &amp; Businesses
                  </h2>
                </div>
                {testimonials.map(
                  (t) =>
                    productTabs === t.id && (
                      <div className="pricing-testimonial-box">
                        <div className="flex justify-content-between align-items-center">
                          <div className="pricing-testimonial-img hidden-mob">
                            <img
                              src={t.imgURL}
                              alt="Afton Negrea testimonial img"
                              title="Afton Negrea testimonial image"
                            />
                          </div>
                          <div className="pricing-testimonial-content">
                            <div className="app-sumo-rating">
                              <img
                                loading="lazy"
                                src="/assets/img/stars.svg"
                                alt="5 star icons in a row"
                                title="5 star icons in a row"
                                style={{ maxWidth: 96 }}
                              />
                            </div>
                            <p className="content">{t.content}</p>
                            <div className="author">{t.author}</div>
                            <div className="author-designation">
                              {t.authorDesignation}
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
                <a
                  href="/customer-stories/"
                  className="button-transparent visible-mob">
                  Read our customer stories
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: 'rotate(270deg)' }}>
                    <path
                      d="M7 1.16663V12.8333M12.8333 6.99996L7 12.8333L1.16666 6.99996"
                      stroke="#6552FF"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <div className="logo-box">
                  <div className="styled-trusted-by">
                    <div className="container">
                      <div className="flex justify-content-center align-items-center">
                        {brandLogosBugTrack.map(
                          ({ name, image, id, width, height }) => (
                            <div key={id} className="logo-image">
                              <img
                                loading="lazy"
                                src={image}
                                alt={name}
                                title={name}
                                width={width}
                                height={height}
                              />
                            </div>
                          ),
                        )}
                      </div>
                      <p className="center">
                        More than&nbsp;
                        {customerNumber}
                        &nbsp;creators and businesses across the world trust
                        ruttl
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <PricingTable
              pricingData={getPricingTable()}
              views={views}
              currentTab={currentTab}
              toggleSignup={toggleSignup}
              togglePurchaseModal={togglePurchaseModal}
              toggleTab={toggleTab}
              productTabs={productTabs}
              getProPlanValue={getProPlanValue}
              getStandardPlanValue={getStandardPlanValue}
              getTeamPlanValue={getTeamPlanValue}
              websitePlan={websitePlan}
              setWebsitePlan={setWebsitePlan}
              enterpriseData={getEnterpriseData()}
              setCurrentTab={setCurrentTab}
            />

            {/* <section className="pricing-hero" ref={productTable}>
              <div className="container">
                <div className="coupon-wrapper">
                  <OfferCoupon
                    id="1"
                    saveAmount="Save $144 🔥"
                    discount="30% OFF"
                    desc="Offer applicable on Yearly Pro Plan"
                    code="RUTTL30"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                  <OfferCoupon
                    id="2"
                    saveAmount="Save $36 🔥"
                    discount="20% OFF"
                    desc="Offer applicable on Yearly Standard Plan"
                    code="HELLO20"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                  <OfferCoupon
                    id="3"
                    saveAmount="Save $27 🔥"
                    discount="15% OFF"
                    desc="Offer applicable on all monthly Plans"
                    code="RUTTL15"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                </div>
              </div>
            </section> */}

            <NewFaq data={faqData} />
          </main>
          <div
            className={cn([
              'contact-us-modal',
              { show: typeof showPurchaseModal === 'number' },
            ])}
            id="purchase-modal">
            <div className="contact-us-container">
              <button
                type="button"
                className="button-unstyled close-button"
                disabled={fetchingLink}
                onClick={togglePurchaseModal(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="none"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
              {!showDemoCallModal ? (
                <>
                  <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
                    Contact Sales
                  </h3>
                  <form
                    autoComplete="off"
                    onSubmit={formProps.onSubmit}
                    id="contact-sales">
                    <div className="">
                      <div className="form-group">
                        <h4>
                          Name
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('name').length
                            ? formProps.getErrors('name').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="name">
                          <input
                            className="flex-grow-1 flex-basis-0"
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={handleChange}
                            value={formProps.values.name}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <h4 className="flex-grow-1">
                          Work Email
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('email').length
                            ? formProps.getErrors('email').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="email">
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={formProps.values.email}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <div className="team-member">
                          <h4>Team Members</h4>
                          <div className="pricing-team-dropdown">
                            <button
                              type="button"
                              className="button-unstyled"
                              onClick={toggleTeamDropdown}>
                              <strong>{teamMembers}</strong>
                              &nbsp;members
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14px"
                                  height="100%"
                                  viewBox="0 0 512 512"
                                  fill="#fff"
                                  style={{ verticalAlign: 'middle' }}>
                                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                                </svg>
                              </span>
                            </button>
                            <div
                              className={`team-dropdown ${
                                teamDropdown ? 'show' : ''
                              }`}>
                              {[5, 10, 15, 20, 25].map((i) => (
                                <button
                                  key={i}
                                  data-size={i}
                                  type="button"
                                  className="button-unstyled"
                                  onClick={updateTeamMembers}>
                                  {teamMembers === i ? (
                                    <strong>{`${i} members`}</strong>
                                  ) : (
                                    `${i} members`
                                  )}
                                </button>
                              ))}
                            </div>
                            {teamDropdown && (
                              <button
                                type="button"
                                className="button-unstyled absolute-inset"
                                onClick={toggleTeamDropdown}
                                style={{
                                  position: 'fixed',
                                  cursor: 'default',
                                }}>
                                <span />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="button button-green"
                        type="submit"
                        disabled={loading}>
                        {loading ? 'loading...' : 'Book Demo Call'}
                      </button>
                    </div>
                    {/* <button type="reset" onClick={formProps.reset}>Reset</button> */}
                  </form>
                </>
              ) : (
                <>
                  <h2>Thank you</h2>
                  <p>
                    Thanks for showing interest in our enterprise plan,
                    we&apos;d be glad to connect with you over a call to
                    understand your team requirement better. Please schedule a
                    call with us using the link below:
                  </p>
                  <div>
                    <a
                      className="button"
                      href="https://calendly.com/setup-demo-with-ruttl/30min"
                      target="_blank"
                      rel="noopener noreferrer">
                      Schedule a Demo
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ProductPricing;
